import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import logo from "../images/logo.jpg";
import { getCartCookies, removeCookies } from "../utils/cookies";
import {
  isValidCard,
  isValidCheckoutPage,
  validateForm,
} from "../utils/validation";
import PaymentSuccessPop from "../components/popup/paymentSuccessPop";
import Tooltip from "../components/tooltip";
import {
  getShippingRate,
  initShippingRate,
  orderCreate,
  quoteCreate,
} from "../redux/apis/orderAPI";
import { useDispatch, useSelector } from "react-redux";
import { pageRefresh } from "../redux/apis/pageAPI";
import { calculateTax, cartItemsCount } from "../utils/cart";
import PaymentFailedPop from "../components/popup/paymentFailedPop";
import TopNotificationBar from "../components/popup/topNotificationBar";
import PaymentPendingPop from "../components/popup/paymentPendingPop";

const states = [
  { value: "", label: "Canada" },
  { value: "AB", label: "AB" },
  { value: "BC", label: "BC" },
  { value: "MB", label: "MB" },
  { value: "NB", label: "NB" },
  { value: "NL", label: "NL" },
  { value: "NWT", label: "NWT" },
  { value: "NS", label: "NS" },
  { value: "NU", label: "NU" },
  { value: "ON", label: "ON" },
  { value: "PEI", label: "PEI" },
  { value: "QC", label: "QC" },
  { value: "SK", label: "SK" },
  { value: "YT", label: "YT" },
  { value: "", label: "United States" },
  { value: "AL", label: "AL" },
  { value: "AK", label: "AK" },
  { value: "AZ", label: "AZ" },
  { value: "AR", label: "AR" },
  { value: "CA", label: "CA" },
  { value: "CO", label: "CO" },
  { value: "CT", label: "CT" },
  { value: "DC", label: "DC" },
  { value: "DE", label: "DE" },
  { value: "FL", label: "FL" },
  { value: "GA", label: "GA" },
  { value: "HI", label: "HI" },
  { value: "ID", label: "ID" },
  { value: "IL", label: "IL" },
  { value: "IN", label: "IN" },
  { value: "IA", label: "IA" },
  { value: "KS", label: "KS" },
  { value: "KY", label: "KY" },
  { value: "LA", label: "LA" },
  { value: "ME", label: "ME" },
  { value: "MD", label: "MD" },
  { value: "MA", label: "MA" },
  { value: "MI", label: "MI" },
  { value: "MN", label: "MN" },
  { value: "MS", label: "MS" },
  { value: "MO", label: "MO" },
  { value: "MT", label: "MT" },
  { value: "NE", label: "NE" },
  { value: "NV", label: "NV" },
  { value: "NH", label: "NH" },
  { value: "NJ", label: "NJ" },
  { value: "NM", label: "NM" },
  { value: "NY", label: "NY" },
  { value: "NC", label: "NC" },
  { value: "ND", label: "ND" },
  { value: "OH", label: "OH" },
  { value: "OK", label: "OK" },
  { value: "OR", label: "OR" },
  { value: "PA", label: "PA" },
  { value: "RI", label: "RI" },
  { value: "SC", label: "SC" },
  { value: "SD", label: "SD" },
  { value: "TN", label: "TN" },
  { value: "TX", label: "TX" },
  { value: "UT", label: "UT" },
  { value: "VT", label: "VT" },
  { value: "VA", label: "VA" },
  { value: "WA", label: "WA" },
  { value: "WV", label: "WV" },
  { value: "WI", label: "WI" },
  { value: "WY", label: "WY" },
];

const cards = [
  { value: "Visa", label: "Visa" },
  { value: "Mastercard", label: "Mastercard" },
];

const country = [
  { value: "", label: "Country" },
  { value: "US", label: "US" },
  { value: "CA", label: "CA" },
];

const today = new Date();
const tomorrow = new Date(today);
tomorrow.setDate(today.getDate() + 1);
const tomorrowISOString = tomorrow.toISOString().split("T")[0];

const Checkout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const originalTotal = location.state.subtotal;
  const checkoutState = location.state.checkoutState;
  const totalWeight = location.state.totalWeight;
  const totalQuantity = location.state.totalQuantity;
  const [cartDetail, setCartDetail] = useState(location.state.cartDetail);

  //initial status
  const initialCheckedbox = useRef(true);

  const [orderTotal, setOrderTotal] = useState(originalTotal);
  const [tax, setTax] = useState(false);
  const [taxRate, setTaxRate] = useState();
  const shipping = useSelector((state) => state.orderReducer.shippingFee);
  const [shippingFee, setShippingFee] = useState(shipping);
  const shippingFeePending = useSelector(
    (state) => state.pageReducer.shippingFeePending
  );
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [eventName, setEventName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [altPhone, setAltPhone] = useState("");
  const [residential, setResidential] = useState("Residence");
  //top bar for residential
  const [topBar, setTopBar] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({
    company: "",
    address: "",
    city: "",
    state: "",
    country: "",
    code: "",
  });
  const [diffAdd, setDiffAdd] = useState(true);
  const [billingAddress, setBillingAddress] = useState({
    company: "",
    address: "",
    city: "",
    state: "",
    country: "",
    code: "",
  });
  const [billingEmail, setBillingEmail] = useState("");
  const [dateEvent, setDateEvent] = useState("");
  const [dateHand, setDateHand] = useState("");
  const [cardNo, setCardNo] = useState("");
  const [cardType, setCardType] = useState("Visa");
  const [cardDate, setCardDate] = useState("");
  const [cardCVV, setCardCVV] = useState("");
  const [additionalInstructions, setAdditionalInstructions] = useState("");
  const [successPay, setSuccessPay] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [pending, setPending] = useState(false); //submit pending

  const handleCardDateChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    if (inputValue.length > 2) {
      inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}`;
    }
    setCardDate(inputValue);
  };

  const checkResidential = (isShippingChange) => {
    if (!initialCheckedbox.current) {
      if (residential === "Non-residence") {
        setShippingFee((prev) => Number(prev) + 4.5);
        setTopBar(true);
      }
      if (residential === "Residence" && !isShippingChange) {
        setShippingFee((prev) => Number(prev) - 4.5);
        setTopBar(false);
      }
    }
  };

  const handleCardNoChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 16);
    if (inputValue.length > 0) {
      inputValue = inputValue.match(/.{1,4}/g).join(" ");
    }
    setCardNo(inputValue);
  };

  const handleCardCVVChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/\D/g, "");
    inputValue = inputValue.slice(0, 3);
    setCardCVV(inputValue);
  };

  const makeAPayment = async () => {
    if (
      disableSubmit === true ||
      tax === false ||
      isNaN(tax) ||
      shipping === "Error"
    ) {
      return;
    }
    if (!isValidCard(cardNo) && checkoutState === "checkout") {
      setErrorMessage("Card number is invalid!");
      setDisableSubmit(false);
      return;
    }
    setDisableSubmit(true);
    setErrorMessage("");
    const submitData = {
      orderStatus: 0,
      subtotal: Number(originalTotal).toFixed(2),
      grandTotal: Number(orderTotal).toFixed(2),
      shippingFee: Number(shippingFee).toFixed(2),
      tax: Number(tax).toFixed(2),
      taxRate,
      eventName: shippingAddress.company,
      firstName,
      lastName,
      email,
      phone,
      altPhone,
      fax: "",
      residential,
      shippingAddress,
      billingAddress: diffAdd ? shippingAddress : billingAddress,
      billingEmail: billingEmail == "" ? email : billingEmail,
      dateEvent,
      dateHand,
      cardNo,
      cardType,
      cardDate,
      cardCVV,
      additionalInstructions,
    };
    let ignoreList = ["altPhone", "fax", "additionalInstructions"];
    if (checkoutState === "quote") {
      submitData.shippingAddress = {
        address: "NA",
        city: "NA",
        code: "NA",
        company: shippingAddress.company,
        country: "NA",
        state: "NA",
      };
      submitData.billingAddress = submitData.shippingAddress;
      ignoreList = [
        "tax",
        "taxRate",
        "fax",
        "altPhone",
        "shippingFee",
        "residential",
        "shippingAddress",
        "billingAddress",
        "billingEmail",
        "dateEvent",
        "dateHand",
        "cardNo",
        "cardType",
        "cardDate",
        "cardCVV",
        "additionalInstructions",
      ];
    }

    const vali = validateForm(submitData, ignoreList);
    if (vali === true) {
      //checkout
      if (checkoutState === "checkout") {
        if (isNaN(Number(shippingFee).toFixed(2))) {
          setErrorMessage("Delivery address is invalid.");
          setDisableSubmit(false);
          return;
        }
        setPending(true);
        const res = await orderCreate({
          payment: submitData,
          cart: cartDetail,
        });
        if (res?.msg === "ok") {
          setSuccessPay(true);
          setPending(false);
          await removeCookies(checkoutState);
        } else {
          setSuccessPay(false);
          setPending(false);
        }
      } else {
        //quote
        setPending(true);
        const res = await quoteCreate({
          payment: submitData,
          cart: cartDetail,
        });
        if (res?.msg === "ok") {
          setSuccessPay(true);
          setPending(false);
          await removeCookies(checkoutState);
        } else {
          setSuccessPay(false);
          setPending(false);
        }
      }
    } else {
      setErrorMessage(vali);
      setDisableSubmit(false);
    }
  };

  const getQuoteCart = async () => {
    const quoteCart = await getCartCookies("quote");
    setCartDetail(quoteCart);
    setDisableSubmit(false);
    setTax(0);
  };

  //top bar display
  useEffect(() => {
    let timeoutId;
    if (topBar) {
      timeoutId = setTimeout(() => {
        setTopBar(false);
      }, 5000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [topBar]);

  //shipping & tax
  // Use useRef to keep track of the previous shippingAddress
  const prevShippingAddressRef = useRef();
  useEffect(() => {
    prevShippingAddressRef.current = shippingAddress;
  }, [shippingAddress]);

  const prevShippingAddress = prevShippingAddressRef.current;

  useEffect(() => {
    //wait 3 seconds after no change
    let timeoutId;
    // Check if shippingAddress has changed
    if (shippingAddress !== prevShippingAddress) {
      // If it has changed, clear any existing timeout
      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      // Set a new timeout to dispatch after 3 seconds
      timeoutId = setTimeout(() => {
        dispatch(getShippingRate(shippingAddress, totalWeight));
      }, 3000);
    }
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [shippingAddress]);

  useEffect(() => {
    if (shipping !== "Error") {
      setShippingFee(shipping);
      checkResidential(true);
    }
  }, [shipping]);

  useEffect(() => {
    const newR = calculateTax(
      Number(originalTotal) + Number(shippingFee),
      shippingAddress.state
    );
    setTax(newR.tax);
    setTaxRate(newR.taxRate);
    setOrderTotal(newR.total);
  }, [shippingFee]);
  //end shipping & tax

  useEffect(() => {
    if (isValidCheckoutPage(checkoutState)) {
      dispatch(initShippingRate());
    }
    if (checkoutState === "quote") {
      getQuoteCart();
    }
  }, []);

  //update shipping fee and subtotal
  useEffect(() => {
    checkResidential(false);
  }, [residential]);

  return (
    <div>
      <div className="flex flex-col items-center border-b bg-white py-4 px-16 sm:flex-row">
        <div onClick={() => navigate(`/`)} className="cursor-pointer">
          <img
            className="logo-size w-auto cursor-pointer"
            src={logo}
            alt="logo"
          />
        </div>
        <div className="mt-4 py-2 text-xs sm:mt-0 sm:ml-auto sm:text-base">
          <div className="relative">
            <ul className="relative flex w-full items-center justify-between space-x-2 sm:space-x-4">
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <div
                  className="flex h-6 w-6 items-center cursor-pointer justify-center rounded-full bg-indigo-200 text-xs font-semibold text-indigo-700"
                  onClick={() => navigate(-1)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M5 13l4 4L19 7"
                    />
                  </svg>
                </div>
                <span className="font-semibold text-gray-900">Shop</span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-indigo-600 text-xs font-semibold text-white ring ring-gray-600 ring-offset-2">
                  2
                </div>
                <span className="font-semibold text-gray-900">
                  Purchase Info
                </span>
              </li>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 5l7 7-7 7"
                />
              </svg>
              <li className="flex items-center space-x-3 text-left sm:space-x-4">
                <div className="flex h-6 w-6 items-center justify-center rounded-full bg-indigo-400 text-xs font-semibold text-white">
                  3
                </div>
                <span className="font-semibold text-gray-500">Success</span>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="grid lg:grid-cols-2 sm:flex-row">
        {/*FIRST BLOCK*/}
        <div className="checkout-padding-left mt-10 px-8 pt-8 lg:mt-0 lg:order-first">
          <p className="text-xl font-medium mb-4">Details</p>
          <div className="mb-6">
            {/* DATE */}
            {checkoutState === "checkout" && (
              <div className="mb-6 block text-sm flex flex-col sm:flex-row">
                <div className="flex-1">
                  <label htmlFor="dateEvent" className="mb-2 block text-sm ">
                    Date Of Event*
                  </label>
                  <input
                    type="date"
                    id="dateEvent"
                    value={dateEvent}
                    onChange={(e) => setDateEvent(e.target.value)}
                    min={tomorrowISOString}
                    className="p-2 border border-gray-300 rounded"
                  />
                </div>
                <div className="flex-1">
                  <label htmlFor="dateHand" className="mb-2 block text-sm ">
                    In Hand By*
                  </label>
                  <input
                    type="date"
                    id="dateHand"
                    value={dateHand}
                    onChange={(e) => setDateHand(e.target.value)}
                    min={tomorrowISOString}
                    className="p-2 border border-gray-300 rounded"
                  />
                </div>
              </div>
            )}
            {/* ADDITIONAL INSTRUCTIONS */}
            <label
              htmlFor="additionalInstructions"
              className="block text-sm font-medium text-gray-700 mt-2"
            >
              Additional Instructions
            </label>
            <textarea
              id="additionalInstructions"
              name="additionalInstructions"
              value={additionalInstructions}
              onChange={(e) => setAdditionalInstructions(e.target.value)}
              rows={4} // Adjust the number of rows as needed
              className="mt-1 p-2 block w-full border border-gray-200 rounded-md outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm"
              placeholder="Additional order instructions"
            />

            <p className="text-xl font-medium mb-4 mt-4">Shipping</p>
            <div className="mb-6">
              {/* BILLING NAME */}
              <div className="relative pb-2">
                <input
                  onChange={(e) =>
                    setShippingAddress({
                      ...shippingAddress,
                      company: e.target.value,
                    })
                  }
                  value={shippingAddress.company}
                  type="text"
                  className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                  placeholder="Company (if shipping to business address)"
                />
              </div>
              {/* NAME */}
              <div className="relative py-2">
                <div className="flex">
                  <input
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    name="first_name"
                    type="text"
                    className="w-1/2 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="First Name"
                  />
                  <input
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    name="last_name"
                    type="text"
                    className="w-1/2 flex-shrink-0 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Last Name"
                  />
                </div>
              </div>
              {checkoutState === "checkout" ? (
                <div>
                  {/* SHIPPING ADDRESS */}
                  <input
                    onChange={(e) =>
                      setShippingAddress({
                        ...shippingAddress,
                        address: e.target.value,
                      })
                    }
                    value={shippingAddress.address}
                    type="text"
                    name="address"
                    className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    placeholder="Shipping Address (No PO Boxes)"
                  />
                  <div className="flex flex-col sm:flex-row mt-4">
                    <input
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          city: e.target.value,
                        })
                      }
                      value={shippingAddress.city}
                      type="text"
                      name="city"
                      className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="City"
                    />
                    <select
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          state: e.target.value,
                        })
                      }
                      value={shippingAddress.state}
                      type="text"
                      className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="" disabled hidden>
                        State/Prov
                      </option>
                      {states.map((state, index) => (
                        <option
                          disabled={state.value === "" ? true : false}
                          key={index}
                          value={state.value}
                        >
                          {state.label}
                        </option>
                      ))}
                    </select>
                    <select
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          country: e.target.value,
                        })
                      }
                      value={shippingAddress.country}
                      type="text"
                      className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      <option value="" disabled hidden>
                        Country
                      </option>
                      {country.map((coun, index) => (
                        <option
                          key={index}
                          value={coun.value}
                          disabled={coun.value === "" ? true : false}
                        >
                          {coun.label}
                        </option>
                      ))}
                    </select>
                    <input
                      onChange={(e) =>
                        setShippingAddress({
                          ...shippingAddress,
                          code: e.target.value,
                        })
                      }
                      value={shippingAddress.code}
                      type="text"
                      name="postalCode"
                      className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Postal/Zip"
                    />
                  </div>
                  {/* PHONE */}
                  <div className="relative py-2 mt-2">
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      name="phone"
                      id="phone"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Daytime Phone Number"
                    />
                  </div>
                  <div className="relative py-2">
                    <input
                      onChange={(e) => setAltPhone(e.target.value)}
                      value={altPhone}
                      type="text"
                      name="phone"
                      id="phone"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Alternate Phone Number"
                    />
                  </div>
                  {/* EMAIL */}
                  <div className="relative py-2">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      id="email"
                      name="email"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="flex items-center mt-4 pb-2 block text-sm font-medium relative">
                    <label htmlFor="residentialCheck" className="text-gray-700">
                      For Residential Address
                    </label>

                    {/* <input
                      id="residentialCheck"
                      onChange={() => {
                        setResidential(!residential);
                        initialCheckedbox.current = false;
                      }}
                      checked={residential}
                      type="checkbox"
                      className="form-checkbox h-5 w-5 text-indigo-600"
                    /> */}
                    <Tooltip
                      x="right"
                      message="If the shipping company deems the address to be residential, an additional $4.50 will automatically be added to your shipping total. Home based businesses are considered residential addresses."
                    />
                  </div>

                  <select
                    value={residential}
                    onChange={(e) => {
                      setResidential(e.target.value);
                      initialCheckedbox.current = false;
                    }}
                    className="w-full border border-gray-200 px-4 py-3 pb-4 text-sm shadow-sm focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                  >
                    <option value="Non-residence">Non-residence</option>
                    <option value="Residence">Residence</option>
                  </select>

                  <p className="text-xl font-medium mb-4 mt-6">Billing</p>
                  <div className="mb-6">
                    {/* BILLING ADDRESS */}
                    <div className="flex items-center mt-4 pb-4 block text-sm font-medium">
                      <input
                        id="myCheckbox"
                        onChange={() => setDiffAdd(!diffAdd)}
                        checked={diffAdd}
                        type="radio"
                        className="form-radio h-5 w-5 text-indigo-600"
                      />
                      <label
                        htmlFor="myCheckbox"
                        className="ml-2 text-gray-700"
                      >
                        {/* Use shipping address as billing address */}
                        {/* Is billing address the same as shipping address? */}
                        Use my shipping address
                      </label>
                    </div>
                    <div className="flex items-center pb-4 block text-sm font-medium">
                      <input
                        id="myCheckbox2"
                        onChange={() => setDiffAdd(!diffAdd)}
                        checked={!diffAdd}
                        type="radio"
                        className="form-radio h-5 w-5 text-indigo-600"
                      />
                      <label
                        htmlFor="myCheckbox2"
                        className="ml-2 text-gray-700"
                      >
                        {/* Use shipping address as billing address */}
                        {/* Is billing address the same as shipping address? */}
                        Use my shipping address with changes
                      </label>
                    </div>
                    {!diffAdd ? (
                      <div>
                        <div className="pb-4">
                          <input
                            onChange={(e) =>
                              setBillingAddress({
                                ...shippingAddress,
                                company: e.target.value,
                              })
                            }
                            value={billingAddress.company}
                            type="text"
                            className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Bill to Company or Name"
                          />
                        </div>
                        {/* <input
                          onChange={(e) =>
                            setBillingAddress({
                              ...billingAddress,
                              address: e.target.value,
                            })
                          }
                          value={billingAddress.address}
                          type="text"
                          name="address"
                          className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                          placeholder="Billing Address (if different than shipping address)"
                        /> */}
                        {/* <div className="flex flex-col sm:flex-row  mt-4">
                          <input
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                city: e.target.value,
                              })
                            }
                            value={billingAddress.city}
                            type="text"
                            name="city"
                            className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="City"
                          />
                          <select
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                state: e.target.value,
                              })
                            }
                            value={billingAddress.state}
                            type="text"
                            className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            <option value="" disabled hidden>
                              State/Prov
                            </option>
                            {states.map((state, index) => (
                              <option
                                disabled={state.value === "" ? true : false}
                                key={index}
                                value={state.value}
                              >
                                {state.label}
                              </option>
                            ))}
                          </select>
                          <select
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                country: e.target.value,
                              })
                            }
                            value={billingAddress.country}
                            type="text"
                            className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                          >
                            <option value="" disabled hidden>
                              Country
                            </option>
                            {country.map((coun, index) => (
                              <option
                                key={index}
                                value={coun.value}
                                disabled={coun.value === "" ? true : false}
                              >
                                {coun.label}
                              </option>
                            ))}
                          </select>
                          <input
                            onChange={(e) =>
                              setBillingAddress({
                                ...billingAddress,
                                code: e.target.value,
                              })
                            }
                            value={billingAddress.code}
                            type="text"
                            name="postalCode"
                            className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none sm:w-1/6 focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Postal/Zip"
                          />
                        </div> */}
                        <div className="relative py-2">
                          <input
                            onChange={(e) => setBillingEmail(e.target.value)}
                            value={billingEmail}
                            type="text"
                            id="email"
                            name="email"
                            className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                            placeholder="Email Address (if different than shipping email)"
                          />
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>

                  {/* CARD */}
                  <p className="text-xl font-medium mt-4 mb-4">Payment</p>
                  <div className="flex">
                    <select
                      onChange={(e) => setCardType(e.target.value)}
                      value={cardType}
                      type="text"
                      className="flex-1 rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                    >
                      {cards.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                    </select>
                    <div className="relative w-5/12 flex-shrink-0">
                      <input
                        type="text"
                        onChange={(e) => handleCardNoChange(e)}
                        value={cardNo}
                        name="card-no"
                        className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                        placeholder="xxxx xxxx xxxx xxxx"
                      />
                    </div>
                    <input
                      onChange={(e) => handleCardDateChange(e)}
                      value={cardDate}
                      type="text"
                      name="credit-expiry"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="MM/YY"
                    />
                    <input
                      onChange={(e) => handleCardCVVChange(e)}
                      value={cardCVV}
                      type="text"
                      name="credit-cvc"
                      className="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="CVV"
                    />
                  </div>
                </div>
              ) : (
                <>
                  {/* PHONE */}
                  <div className="relative py-2">
                    <input
                      onChange={(e) => setPhone(e.target.value)}
                      value={phone}
                      type="text"
                      name="phone"
                      id="phone"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Daytime Phone Number"
                    />
                  </div>
                  <div className="relative py-2">
                    <input
                      onChange={(e) => setAltPhone(e.target.value)}
                      value={altPhone}
                      type="text"
                      name="phone"
                      id="phone"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Alternate Phone Number"
                    />
                  </div>
                  {/* EMAIL */}
                  <div className="relative py-2">
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="text"
                      id="email"
                      name="email"
                      className="w-full rounded-md border border-gray-200 px-4 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-indigo-500 focus:ring-indigo-500"
                      placeholder="Email Address"
                    />
                  </div>
                </>
              )}
            </div>
          </div>
          {errorMessage != "" && (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 rounded relative mb-4"
              role="alert"
            >
              <strong className="font-bold">Error! </strong>
              <span className="block sm:inline">{errorMessage}</span>
            </div>
          )}
          <div
            onClick={makeAPayment}
            className={`mb-6 w-full rounded-md px-6 py-3 font-medium text-white hover:text-white text-center ${
              disableSubmit || tax === false || isNaN(tax)
                ? "bg-gray-500 cursor-not-allowed"
                : "bg-indigo-600 cursor-pointer"
            }`}
          >
            {disableSubmit || tax === false || isNaN(tax) ? (
              <div role="status" className="w-fit m-auto">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-100 animate-spin dark:text-gray-200 fill-indigo-400"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
              </div>
            ) : checkoutState === "checkout" ? (
              "Place Order"
            ) : (
              "Ask For A Quote"
            )}
          </div>

          <p className="text-sm text-gray-500 mb-8">
            <span className="text-red-500">NOTE:</span> This summary is only a
            quote; your credit card will not be charged until the bibs have been
            shipped.
            <br /> Running Count will do its best to ensure these quotes are
            accurate but we have the right to review this quote to ensure there
            are no errors. We will contact you if your price will be different
            from this quoted price.
          </p>
        </div>
        {/*second block*/}
        <div className="px-4 py-8 checkout-padding-right bg-gray-100 order-first lg:order-last">
          <p className="text-xl font-medium">
            {checkoutState === "quote" ? "Quote" : "Order"} Summary
          </p>
          <div className="mt-2">
            <div className="p-4 overflow-y-auto">
              {cartDetail == undefined ? (
                <div className="text-center">
                  <p>Your cart is empty</p>
                </div>
              ) : (
                cartDetail.products.map((product, index) => (
                  <div key={index}>
                    <div className="flex flex-row justify-between items-stretch mb-6 mt-4">
                      <div className="w-full h-full lg:w-1/2 pr-0 lg:pr-4 flex items-stretch mb-4 lg:mb-0">
                        <div className="flex items-stretch relative">
                          <img
                            src={product.image.src}
                            alt={product.image.alt}
                            className="w-full h-auto rounded"
                          />
                          {/* <div className="absolute inset-y-0 right-0 flex items-center justify-center min-w-6 h-6 rounded-full bg-gray-500 text-white text-xs transform translate-x-1/4 -translate-y-1/3">
                            {product.quantity}
                          </div> */}
                        </div>
                      </div>
                      <div className="w-full lg:w-1/1 pr-0 lg:pr-4 flex flex-col items-stretch gap-2">
                        <div className="flex flex-col items-stretch justify-between h-fill">
                          <div>
                            <p className="text-sm">{product.name}</p>
                            {Object.keys(product.variants).map((t, i) => (
                              <div
                                key={"variant-" + i}
                                className="mt-1 text-sm text-gray-500"
                              >
                                {product.variants[t].length > 0 && `${t} : `}
                                <span className="text-gray-800">
                                  {typeof product.variants[t] === "object"
                                    ? product.variants[t].map((lg, li) => (
                                        <div
                                          key={`${product.id}-variant-obj-${li}`}
                                          className="flex gap-2"
                                        >
                                          {Object.entries(lg).map(
                                            ([key, value]) =>
                                              /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(
                                                value
                                              ) ? (
                                                <a
                                                  className="text-indigo-500 underline"
                                                  key={key}
                                                  href={value}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  File{li}
                                                </a>
                                              ) : (
                                                <p key={key}>{value}</p>
                                              )
                                          )}
                                        </div>
                                      ))
                                    : product.variants[t] === "false"
                                    ? "No"
                                    : product.variants[t] === "true"
                                    ? "Yes"
                                    : product.variants[t] + ""}
                                </span>
                              </div>
                            ))}
                            <div className="mt-1 text-sm text-gray-500 flex gap-2">
                              <div>Total Quantity :</div>
                              <span className="text-gray-800">
                                {product.quantity}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="w-full h-full lg:w-1/2 pr-0 lg:pr-4 flex items-stretch mb-4 lg:mb-0 justify-end">
                        <p className="mt-auto font-medium pr-4 lg:pr-0">
                          ${product.total}
                        </p>
                      </div>
                    </div>

                    {/* Dependency Block */}
                    <div className="mb-6 mt-4 flex flex-col gap-2">
                      {product.dependencies.map((dep, di) =>
                        dep.total > 0 ? (
                          <div
                            className="flex flex-row justify-between items-center bg-indigo-50 py-2 px-11 border"
                            key={"dependency_" + di}
                          >
                            <div className="lg:w-1/2">
                              <p className="text-sm">{dep.name}</p>
                            </div>
                            <div className="lg:w-1/4 text-right">
                              <p className="text-sm font-medium">
                                ({dep.quantity})
                              </p>
                            </div>
                            <div className="lg:w-1/4 text-right">
                              <p className="font-medium">${dep.total}</p>
                            </div>
                          </div>
                        ) : null
                      )}
                    </div>
                  </div>
                ))
              )}
            </div>
            {/* subtotal */}
            <div className="mt-6 border-t border-b py-2">
              {checkoutState === "checkout" && (
                <>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-sm font-medium text-gray-900">
                      Order Quantity
                    </p>
                    <p className="font-semibold text-gray-900">
                      {totalQuantity}
                    </p>
                  </div>
                  <div className="flex items-center justify-between mb-2">
                    <p className="text-sm font-medium text-gray-900">Taxes</p>
                    {tax === false || isNaN(tax) ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-100 animate-spin dark:text-gray-200 fill-indigo-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    ) : (
                      <p className="font-semibold text-gray-900">
                        ${Number(tax).toFixed(2)}
                      </p>
                    )}
                  </div>
                </>
              )}
              <div className="flex items-center justify-between mb-2">
                <p className="text-sm font-medium text-gray-900">Subtotal</p>
                <p className="font-semibold text-gray-900">
                  ${Number(originalTotal).toFixed(2)}
                </p>
              </div>
              {checkoutState === "checkout" && (
                <div className="flex items-center justify-between mb-2">
                  <p className="text-sm font-medium text-gray-900">Shipping</p>
                  <div className="font-semibold">
                    {shippingFeePending ? (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          className="w-5 h-5 text-gray-100 animate-spin dark:text-gray-200 fill-indigo-400"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </div>
                    ) : shipping === "Error" ? (
                      <span className="text-sm text-red-500">
                        Address Error
                      </span>
                    ) : (
                      `$${Number(shippingFee).toFixed(2)}`
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="mt-6 flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Total</p>
              <div className="text-2xl font-semibold text-gray-900">
                <div role="status">
                  {isNaN(orderTotal) ? (
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-100 animate-spin dark:text-gray-200 fill-indigo-400"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  ) : (
                    "$" + Number(orderTotal).toFixed(2)
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {successPay === true ? (
        <PaymentSuccessPop checkoutState={checkoutState} />
      ) : successPay === false && disableSubmit ? (
        <PaymentFailedPop checkoutState={checkoutState} />
      ) : null}
      {pending === true && <PaymentPendingPop checkoutState={checkoutState} />}
      {topBar === true && (
        <TopNotificationBar
          msg={"Additional $4.5 has been added to your shipping fee."}
        />
      )}
    </div>
  );
};

export default Checkout;
