import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getCVV } from "../../redux/apis/orderAPI";
import { isNull } from "../../utils/validation";

export default function DashboardCVV() {
  const [pending, setPending] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const [orderNum, setOrderNum] = useState("");
  const [cvv, setCVV] = useState("");
  const [errorMsg, setErrorMessage] = useState(null);

  useEffect(() => {
    try {
      if (location.state.login) {
        setPending(false);
      } else {
        navigate("/login/dashboard");
      }
    } catch (e) {
      navigate("/login/dashboard");
    }
  }, []);

  const submitForm = async () => {
    setErrorMessage(null);
    if (isNull(orderNum)) {
      setErrorMessage("Error: Order number is empty!");
      return;
    }
    const rt = await getCVV(orderNum);
    if (rt.data) {
      setCVV(rt.data.data);
    }
  };

  return (
    <div className="min-h-full">
      {!pending ? (
        <div>
          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                Dashboard / CVV-Check
              </h1>
            </div>
          </header>

          <div className="mt-10 px-8" style={{ maxWidth: "600px" }}>
            <div className="flex gap-5">
              <div className="w-full">
                <label
                  htmlFor="orderNum"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Order Number
                </label>
                <div className="mt-2">
                  <input
                    onChange={(e) => setOrderNum(e.target.value)}
                    value={orderNum}
                    id="orderNum"
                    name="orderNum"
                    required
                    className="px-4 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="cvv"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    CVV
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    value={cvv}
                    disabled
                    id="cvv"
                    name="cvv"
                    className="px-4 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
            <div>
              <div
                onClick={submitForm}
                className="mt-6 cursor-pointer flex justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Search
              </div>
            </div>

            <p className="mt-5 text-red-700 bold">{errorMsg}</p>
          </div>
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}
