import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../pages/homePage";
import ProductDetail from "../pages/product-detailPage";
import Custom from "../pages/product-customPage";
import FAQ from "../pages/faqPage";
import Dashboard from "../pages/dashboard/dashboardPage";
import DashboardLogin from "../pages/dashboard/dashboard-loginPage";
import Contact from "../pages/contact-usPage";
import Checkout from "../pages/checkoutPage";
import Categories from "../pages/categoriesPage";
import Cart from "../pages/cartPage";
import About from "../pages/about-usPage";
import NotFoundPage from "../pages/404Page";
import OrderDetail from "../components/dashboard/orderDetail";
import EditProduct from "../pages/edit/tmp-productPage";
import EditCustom from "../pages/edit/tmp-customPage";
import ConstructionPage from "../pages/contructionPage";
import DashboardCVV from "../pages/dashboard/dashboard-cvvPage";

export default function () {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/product" element={<ProductDetail />} />
        <Route exact path="/custom-product" element={<Custom />} />
        <Route exact path="/faq" element={<FAQ />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route exact path="/login/dashboard" element={<DashboardLogin />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/products" element={<Categories />} />
        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/about" element={<About />} />
        <Route exact path="/404" element={<NotFoundPage />} />
        <Route
          exact
          path="/under-construction"
          element={<ConstructionPage />}
        />
        <Route exact path="/dashboard/orderdetail" element={<OrderDetail />} />
        <Route exact path="/dashboard/cvv-check" element={<DashboardCVV />} />
        <Route exact path="/edit/product" element={<EditProduct />} />
        <Route exact path="/edit/custom-product" element={<EditCustom />} />
      </Routes>
    </BrowserRouter>
  );
}
