import React, { useEffect, useRef, useState } from "react";
import QuantityButton from "../components/product/quanyityButton";
import Layout from "./Layout";
import AddToCartPop from "../components/popup/addToCartPop";
import Carousel from "../components/carousel";
import Variants from "../components/product/variants";
import { cartAdd, quoteAdd } from "../redux/apis/orderAPI";
import { productGet } from "../redux/apis/productAPI";
import { findObjectInArray } from "../utils/object";
import {
  calculateTotal,
  calculateWeight,
  listenBibSnE,
} from "../utils/product";
import { useNavigate } from "react-router-dom";
import PriceRangeTable from "../components/product/priceRangeTable";
import { isNull, isValidBibStartandEnd } from "../utils/validation";
import ProductDetailsSkeleton from "../components/skeleton/productDetails_Skeleton";

export default function ProductDetail() {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});
  const [addtocartPop, setAddtocartPop] = useState(false);
  const [mainImage, setMainImage] = useState("");
  const [errorMessage, setErrorMessage] = useState({ enable: false, msg: "" });
  const pending = useRef(true);
  const numberChange = useRef(false);
  // cart detail
  const [v, setV] = useState([]);
  const [q, setQ] = useState(1);
  const [totalQ, setTotalQ] = useState(0);
  const [w, setW] = useState(0);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const productData = await productGet();
        setProduct(productData);

        const updatedVariants = Object.keys(productData.variants).reduce(
          (acc, variantKey) => {
            const variantOptions = productData.variants[variantKey];
            const firstInStockOption = variantOptions.find(
              (option) => option.inStock == "1"
            );
            if (firstInStockOption) {
              acc.push({ [variantKey]: firstInStockOption.name });
            }
            return acc;
          },
          []
        );
        setV(updatedVariants);
        setMainImage(productData.image[0].src);
        setQ(productData.unitSet);
        pending.current = false;
      } catch (error) {
        console.error("Error fetching product:", error);
      }
    };

    fetchData();
  }, []);

  //listening on input
  useEffect(() => {
    listenBibSnE(handleNumberChange, numberChange.current);
  }, []);
  const handleNumberChange = () => {
    numberChange.current = true;
  };

  //handle price change when variants change, only happens when product type is bib number
  useEffect(() => {
    if (!pending.current) {
      let newQuantity = q;
      if (product.productType === "plain") {
        newQuantity =
          Number(findObjectInArray(v, "End Number") ?? 0) -
          Number(findObjectInArray(v, "Start Number") ?? 0);

        //check start and end number
        if (newQuantity <= 0 && numberChange.current) {
          newQuantity = 0;
          setErrorMessage({
            enable: true,
            msg: "End Number should be greater than Start Number",
          });
        } else {
          setErrorMessage({ enable: false, msg: "" });
          newQuantity += 1;
          newQuantity = newQuantity * q;
        }
      }
      handlePriceChange(newQuantity);
    }
  }, [v, q]);

  const handleQuantityBox = (quan) => {
    setQ(quan);
    if (/individual/i.test(product.name)) {
      setCurrentVariants({
        "How Many Individual Numbers?": quan,
      });
    } else if (/roll/i.test(product.name)) {
      setCurrentVariants({
        "How Many Rolls?": quan,
      });
    } else {
      setCurrentVariants({
        "How Many Sets?": quan,
      });
    }
  };

  const addToCart = async (e, checkoutState) => {
    if (e) e.preventDefault();
    if (totalQ < 1) {
      setErrorMessage({
        enable: true,
        msg: `${
          findObjectInArray(v, "End Number")
            ? "End Number should be greater than Start Number and cannot be 0"
            : "Quantity cannot be 0"
        }`,
      });
      return;
    }
    if (findObjectInArray(v, "End Number")) {
      if (
        isValidBibStartandEnd(
          findObjectInArray(v, "Start Number"),
          findObjectInArray(v, "End Number")
        )
      ) {
      } else {
        setErrorMessage({
          enable: true,
          msg: "End Number should be greater than Start Number and cannot be 0",
        });
        return;
      }
    }
    let cartValid = { valid: false, data: "" };
    if (checkoutState === "checkout") {
      cartValid = await cartAdd(product, v, totalQ, price, w);
    }
    if (checkoutState === "quote") {
      cartValid = await quoteAdd(product, v, totalQ, price);
    }

    if (cartValid.valid) {
      setErrorMessage({ enable: false, msg: "" });
      if (checkoutState === "checkout") {
        if (product.productType === "plain") {
          setAddtocartPop(true);
        } else {
          navigate(`/cart?id=${cartValid.data}`, {
            state: {
              subtotal: price,
              checkoutState,
            },
          });
        }
      } else {
        navigate(`/checkout?id=${cartValid.data}`, {
          state: {
            subtotal: price,
            checkoutState,
          },
        });
      }
    } else {
      setErrorMessage({
        enable: true,
        msg: cartValid.data + " field is invalid",
      });
    }
  };

  const handlePriceChange = (quantity) => {
    let p = calculateTotal(quantity, product.priceTable, v, product.setupFee);
    setPrice(p.total);
    setTotalQ(quantity);
    setW(calculateWeight(product.weight, product.weightQuantity, quantity));
  };

  const closePopup = () => {
    setAddtocartPop(false);
  };

  const onChangeMainImg = (url) => {
    setMainImage(url);
  };

  const setCurrentVariants = (data) => {
    const updatedVariants = [...v];
    Object.keys(data).forEach((key) => {
      const variantIndex = updatedVariants.findIndex(
        (variant) => key in variant
      );
      let newValue = data[key];
      if (typeof newValue !== "object") {
        newValue = String(newValue);
      }
      if (variantIndex !== -1) {
        updatedVariants[variantIndex] = {
          ...updatedVariants[variantIndex],
          [key]: newValue,
        };
      } else {
        updatedVariants.push({ [key]: newValue });
      }
    });
    setV(updatedVariants);
  };

  return (
    <Layout className="bg-white">
      {!pending.current ? (
        <div className="mx-auto sm:px-6 grid lg:grid-cols-2 gap-x-8">
          {/*LEFT*/}
          <div className="productPanel1 lg:py-10 lg:col-span-1 lg:pb-16 lg:pr-8 lg:pt-6">
            <div className=" aspect-h-4 aspect-w-3 rounded-lg product-img-container">
              <img
                src={mainImage}
                className="w-full object-cover object-center"
                alt={product.name}
              />
            </div>
            {product.image.length > 1 && (
              <div className="mt-4">
                <Carousel
                  images={product.image}
                  onChangeMainImg={onChangeMainImg}
                />
              </div>
            )}
            {!isNull(product.highlights) && (
              <div className="mt-10">
                <h3 className="text-sm font-medium">Details</h3>
                <div className="mt-4">
                  <ul className="list-disc space-y-2 pl-4 text-sm">
                    {product.highlights.map((highlight) => (
                      <li key={highlight} className="text-gray-400">
                        <span className="text-gray-600">{highlight}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            )}
            <PriceRangeTable data={product.priceTable} />
          </div>
          {/*RIGHT*/}
          <div className="productPanel2 mt-4 lg:mt-0 lg:col-span-1 lg:pr-8">
            <h1
              className={`text-2xl font-bold tracking-tight sm:text-3xl ${
                product.productType === "dependency" && "mb-2"
              }`}
            >
              {product.name}
            </h1>
            {product.subTitle && (
              <div
                className="text-sm mt-2 text-gray-600"
                dangerouslySetInnerHTML={{
                  __html: product.subTitle,
                }}
              ></div>
            )}
            {/* {product.productType === "dependency" &&
              !isNull(product.highlights) &&
              product.highlights.map((highlight) => (
                <p key={highlight} className="text-gray-400">
                  <span className="text-gray-600">{highlight}</span>
                </p>
              ))} */}
            <hr className="mt-4 mb-4" />
            <p className="flex justify-between mt-2">
              <span className="text-3xl tracking-tight">${price}</span>
            </p>

            <form className="mt-10">
              <Variants
                original={product.variants}
                init={product.variants}
                setCurrentVariants={setCurrentVariants}
                productName={product.name}
              />
              <div className="mt-6">
                <h3 className="text-sm font-medium">
                  {product.productType === "dependency"
                    ? "Quantity"
                    : /individual/i.test(product.name)
                    ? "How Many Individual Numbers?"
                    : /roll/i.test(product.name)
                    ? "How Many Rolls"
                    : "How Many Sets?"}
                </h3>
                <div className="mt-2 lg:w-1/2">
                  <QuantityButton
                    qua={q}
                    setQ={handleQuantityBox}
                    min={product.unitSet}
                    unitSet={product.unitSet}
                    max={product.maxThreshold}
                  />
                </div>
                <p className="mt-4 font-bold">(Total Quantity: {totalQ})</p>
              </div>
              <div className="mt-8">
                {errorMessage.enable ? (
                  <div
                    className="bg-red-100 border border-red-400 text-red-700 px-4 rounded relative"
                    role="alert"
                  >
                    <strong className="font-bold">Error! </strong>
                    <span className="block sm:inline">{errorMessage.msg}</span>
                  </div>
                ) : null}
                <button
                  onClick={(event) => addToCart(event, "checkout")}
                  className="mt-4 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-indigo-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Add To Cart
                </button>

                <button
                  onClick={(event) => addToCart(event, "quote")}
                  className="mt-5 flex w-full items-center justify-center rounded-md border border-indigo-600 bg-transparent px-8 py-3 text-base font-medium text-indigo-500"
                >
                  Get A Quote
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        <ProductDetailsSkeleton />
      )}
      {addtocartPop === true ? <AddToCartPop closeFunc={closePopup} /> : null}
    </Layout>
  );
}
