import React, { useEffect, useRef, useState } from "react";
import QuantityButton from "../components/product/quanyityButton";
import Layout from "./Layout";
import { TrashIcon, PencilSquareIcon } from "@heroicons/react/24/outline";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cartUpdateProduct } from "../redux/apis/orderAPI";
import { getCartCookies, refreshCookiesTime } from "../utils/cookies";
import { isNull } from "../utils/validation";
import { pageRefresh } from "../redux/apis/pageAPI";
import {
  cartItemsCount,
  cartSubtotalCalculate,
  cartWeightCalculate,
  getEditCart,
} from "../utils/cart";
import Tooltip from "../components/tooltip";
import { calculateDependencyTotal } from "../utils/product";

const Cart = () => {
  const [cartDetail, setCartDetail] = useState({});
  const [subtotal, setSubtotal] = useState(0);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const apiRefresh = useSelector((state) => state.pageReducer.apiRefresh);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getSessionCart = async () => {
    const sessionCart = await getCartCookies("checkout");
    if (!isNull(sessionCart.id)) {
      setCartDetail((prevCartDetail) => {
        const updatedCartDetail = { ...prevCartDetail, ...sessionCart };
        return updatedCartDetail;
      });
      setSubtotal(cartSubtotalCalculate(sessionCart));
      setTotalWeight(cartWeightCalculate(sessionCart));
      setTotalQuantity(await cartItemsCount("checkout"));
    } else {
      //all items removed
      setCartDetail({});
      setSubtotal(0);
      setTotalWeight(0);
      setTotalQuantity(0);
    }
    dispatch(pageRefresh());
  };
  useEffect(() => {
    getSessionCart();
  }, [refresh]);

  useEffect(() => {
    dispatch(pageRefresh());
    console.log(cartDetail);
  }, [cartDetail]);

  // const handlePriceChange = (quantity, index) => {
  //   const p = quantity * Number(cartDetail.products[index].price);
  //   let updatedProduct = cartDetail.products[index];
  //   updatedProduct.total = p.toFixed(2);
  //   updatedProduct.quantity = quantity;
  //   dispatch(cartUpdateProduct(updatedProduct, index, "add"));
  //   setRefresh(!refresh);
  // };

  const handleDependencyPriceChange = async (
    quantity,
    weight,
    p_index,
    d_index
  ) => {
    const p = calculateDependencyTotal(
      quantity,
      cartDetail.products[p_index].dependencies[d_index].priceTable
    );
    let updatedProduct = cartDetail.products[p_index];
    updatedProduct.dependencies[d_index].total = p.toFixed(2);
    updatedProduct.dependencies[d_index].quantity = quantity;
    updatedProduct.weight = Number(updatedProduct.weight) + Number(weight);
    cartUpdateProduct(cartDetail, updatedProduct, p_index, "add").then(() => {
      setRefresh(!refresh);
    });
  };

  // const handleDependencyWeightChange = async (weight, p_index) => {
  //   let updatedProduct = cartDetail.products[p_index];
  //   updatedProduct.weight = Number(updatedProduct.weight) + Number(weight);
  //   await cartUpdateProduct(cartDetail, updatedProduct, p_index, "add");
  // };

  const removeProduct = async (index) => {
    await cartUpdateProduct(cartDetail, "", index, "rm");
    setRefresh(!refresh);
  };

  const editProduct = async (index) => {
    const currentCart = await getEditCart(cartDetail, index);
    if (currentCart.currentProduct.productType === "custom") {
      navigate(`/edit/custom-product?id=${currentCart.currentProduct.id}`, {
        state: {
          currentCart: cartDetail,
          currentProduct: currentCart.currentProduct,
          currentIndex: index,
          currentCartID: currentCart.id,
        },
      });
    } else {
      navigate(`/edit/product?id=${currentCart.currentProduct.id}`, {
        state: {
          currentCart: cartDetail,
          currentProduct: currentCart.currentProduct,
          currentIndex: index,
          currentCartID: currentCart.id,
        },
      });
    }
  };

  const redirectToCheckout = (checkoutState) => {
    // const ableToRefresh = refreshCookiesTime(cartDetail);
    const ableToRefresh = true;
    if (ableToRefresh) {
      navigate(`/checkout?id=${cartDetail.id}`, {
        state: {
          subtotal,
          checkoutState,
          totalWeight,
          totalQuantity,
          cartDetail,
        },
      });
    } else {
      console.log("cart expired");
      setCartDetail({});
    }
  };

  return (
    <Layout>
      <div className="mx-auto">
        <h1 className="text-2xl font-bold tracking-tight sm:text-3xl mb-8">
          Shopping Cart
        </h1>
        <div className="flex flex-col lg:flex-row">
          {/* Left Side - Cart Details */}
          <div className="w-full lg:w-2/3 pr-0 lg:pr-8">
            <div className="hidden lg:flex lg:flex-row justify-between items-stretch mb-2 mt-4">
              <div className="lg:w-1/4">
                <p className="text-gray-500">Product</p>
              </div>
              <div className="lg:w-1/2"></div>
              <div className="lg:w-1/3">
                <p className="text-gray-500">Quantity</p>
              </div>
              <div className="lg:w-1/4 flex justify-center">
                <p className="text-gray-500 pl-4">Total</p>
              </div>
            </div>
            <div className="border-t p-4 mb-4">
              {cartDetail.products == undefined ? (
                <div className="text-center">
                  <p>Your cart is empty</p>
                  <div
                    onClick={() => navigate(`/products`)}
                    className="cursor-pointer mt-2 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Continue Shopping
                  </div>
                </div>
              ) : (
                cartDetail.products.map((product, pi) => (
                  <div className="border-b" key={"detail_" + pi}>
                    <div className="flex flex-col lg:flex-row justify-between items-stretch mb-6 mt-4">
                      <div className="w-full h-full lg:w-1/4 pr-0 lg:pr-4 mb-4 lg:mb-0">
                        <img
                          src={product.image.src}
                          alt={product.image.alt}
                          className="w-full h-auto rounded"
                        />

                        <button
                          className="flex gap-2 items-center underline mt-4"
                          onClick={() => editProduct(pi)}
                        >
                          <PencilSquareIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                          />
                          <p className="text-sm">Edit Item</p>
                        </button>
                      </div>
                      <div className="w-full lg:w-1/2 pr-0 lg:pr-4 flex flex-col items-stretch gap-2">
                        <div className="flex flex-col items-stretch justify-between h-fill">
                          <div className="flex-grow">
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                navigate(
                                  `/${
                                    product.productType === "custom"
                                      ? `custom-product`
                                      : `product`
                                  }?id=${product.id}`
                                )
                              }
                            >
                              <p className="mb-4 font-semibold">
                                {product.name}
                              </p>
                            </div>
                            {Object.keys(product.variants).map((t, i) => (
                              <div
                                key={`${product.id}-variant-${i}-cart${pi}`}
                                className="mt-1 text-sm text-gray-500"
                              >
                                {product.variants[t].length > 0 && `${t} : `}
                                <span className="text-gray-800">
                                  {/* image upload logo specific */}
                                  {typeof product.variants[t] === "object"
                                    ? product.variants[t].map((lg, li) => (
                                        <div
                                          key={`${product.id}-variant-${i}-cart${pi}-obj-${li}`}
                                          className="flex gap-2"
                                        >
                                          {Object.entries(lg).map(
                                            ([key, value]) =>
                                              /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i.test(
                                                value
                                              ) ? (
                                                <a
                                                  className="text-blue-500 underline"
                                                  key={key}
                                                  href={value}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  File{li}
                                                </a>
                                              ) : (
                                                <p key={key}>{value}</p>
                                              )
                                          )}
                                        </div>
                                      ))
                                    : product.variants[t] === "false"
                                    ? "No"
                                    : product.variants[t] === "true"
                                    ? "Yes"
                                    : product.variants[t] + ""}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="lg:hidden mt-auto flex items-center">
                          <div className="flex items-stretch h-fit-content justify-between">
                            <p>
                              Total Quantity: <b>{product.quantity}</b>
                            </p>
                          </div>
                          <div className="lg:hidden flex items-stretch ml-4 h-fit-content justify-between">
                            <button onClick={() => removeProduct(pi)}>
                              <TrashIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="hidden lg:flex lg:w-1/3 flex items-stretch mt-4 lg:mt-0 h-fit-content gap-3">
                        <p className="w-full font-bold">{product.quantity}</p>
                        <button onClick={() => removeProduct(pi)}>
                          <TrashIcon className="h-5 w-5" aria-hidden="true" />
                        </button>
                      </div>
                      <div className="hidden lg:flex lg:w-1/4 items-stretch mt-4 lg:mt-0 flex justify-end h-fit-content">
                        <p className="font-lg px-4 font-bold">
                          ${product.total}
                        </p>
                      </div>
                    </div>
                    {/* dependencies */}
                    <div className="mb-6 mt-4 ">
                      {product.dependencies.map((dep, di) => (
                        <div key={"dependency_" + di} className="flex">
                          <div className="lg:w-1/4"></div>
                          <div className="relative w-full cart-dependency flex flex-row justify-between items-center bg-indigo-50 py-2 px-6">
                            {dep.description && (
                              <Tooltip
                                className="top-0 left-0"
                                message={dep.description}
                                x="left"
                                y="top"
                              ></Tooltip>
                            )}
                            <div className="w-1/2">
                              <p>{dep.name}</p>
                              <p className="text-sm text-gray-600">
                                {dep.description.split(".")[1]}
                              </p>
                            </div>
                            <div className="w-1/3">
                              <div className="text-sm">
                                <QuantityButton
                                  qua={dep.quantity}
                                  setQ={handleDependencyPriceChange}
                                  idx={pi}
                                  d_idx={di}
                                  min={0}
                                  max={dep.maxThreshold}
                                  weight={dep.weight}
                                  setW={true}
                                  disable={apiRefresh}
                                  unitSet={dep.unitSet}
                                />
                              </div>
                            </div>
                            <div className="w-1/5 text-right">
                              <p className="text-sm font-bold">
                                ${Number(dep.total).toFixed(2)}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>

          {/* Right Side - Order Summary */}
          {cartDetail.products == undefined ? (
            <></>
          ) : (
            <div className="w-full lg:w-1/3">
              <div className="p-4 mb-4 bg-gray-100">
                <h2 className="text-lg font-medium mb-4">Order summary</h2>
                <div className="flex justify-between items-center border-b py-2">
                  <span className="text-gray-500 text-sm">Total Quantity:</span>
                  <span className="font-bold">{totalQuantity}</span>
                </div>
                <div className="flex justify-between items-center border-b py-2">
                  <span className="text-gray-500 text-sm">
                    Estimated Total:
                  </span>
                  <span className="font-bold">${subtotal}</span>
                </div>
                <div className="flex justify-between items-center border-b py-2">
                  <span className="text-sm">
                    Taxes and shipping calculated at checkout
                  </span>
                </div>
              </div>
              <button
                onClick={() => redirectToCheckout("checkout")}
                className="mt-10 flex w-full items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-white hover:text-indigo-500 hover:border-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Check Out
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Cart;
